import React from "react"
import "./about.scss"
import TitleSeperatorRounded from "../title-seperator-rounded/TitleSeperatorRounded.jsx"

const About = () => (
  <div className="about section">
    {/* <h2 className="about__title">About</h2> */}
    <TitleSeperatorRounded
      title="ABOUT"
      subtitle="Spreads Plastering"
      titleClass="about__title"
      subtitleClass="about__subtitle"
    />
    <div className="section-content">
      <p className="about__paragraph">
        Reliable, clean and experienced family-owned business serving london and
        the home counties. We provide best in class, high quality plastering at
        reasonable rates.
      </p>
      <p className="about__paragraph">
        With over 250+, 5 stars reviews on RatedPeople.com, we let our work and
        customer satisfaction speaks for itself.
      </p>
      <p className="about__paragraph">
        We offer a wide range of interior plastering services including
        skimming, plaster boarding and coving, as well as exterior plastering
        services including rendering and pebble dashing.
      </p>
      <p className="about__paragraph">
        We love our craft so feel free to give us a call for advice on your next
        job!
      </p>
    </div>
  </div>
)

export default About
