import React from "react"
import "./ratings.scss"
import RatingStar from "./rating-star.js"

const Ratings = () => (
  <div className="ratings-container">
    <div className="ratings__flexbox-container">
      <RatingStar className="ratings__star" />
      <RatingStar className="ratings__star" />
      <RatingStar className="ratings__star" />
      <RatingStar className="ratings__star" />
      <RatingStar className="ratings__star" />
    </div>
    <div className="ratings__star-text">5 Star Rating (260+)</div>
    <h3 className="ratings__text">
      Check our reviews on{" "}
      <a href="https://www.ratedpeople.com/profile/spreads-plastering-services/#ratings">
        RatedPeople.com
      </a>
    </h3>
  </div>
)

export default Ratings
