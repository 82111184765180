import React from "react"
import "./TitleSeperatorRounded.scss"
import classNames from "classnames"

function TitleSeperator(
  { title, subtitle, titleClass, subtitleClass },
  ...props
) {
  return (
    <div className={"title-seperator_container"}>
      {title && (
        <h2 className={classNames("title-seperator__title", titleClass)}>
          {title.toUpperCase()}
        </h2>
      )}
      {subtitle && (
        <h3 className={classNames("title-seperator__subtitle", subtitleClass)}>
          {subtitle.toUpperCase()}
        </h3>
      )}
      <svg
        className={"title-seperator__svg"}
        viewBox="0 0 1242 378"
        height="25vw"
        width="100%"
        preserveAspectRatio="none"
        {...props}
      >
        <linearGradient
          id="prefix__a"
          x1={0}
          x2={1242}
          y1={-1950.985}
          y2={-1950.985}
          gradientTransform="translate(0 2158)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#2473b5" />
          <stop offset={1} stopColor="#3793df" />
        </linearGradient>
        <path
          fill="url(#prefix__a)"
          d="M0 296.3S237.2 432.5 538.5 353c168.3-44.4 293.5-77.8 438-85 69.7-3.5 265.4-2 265.4-30.9v-161c0-22.1-17.9-40-40-40H40c-22.1 0-40 17.9-40 40v220.2z"
        />
        <linearGradient
          id="prefix__b"
          x1={0}
          x2={1242}
          y1={-1987.065}
          y2={-1987.065}
          gradientTransform="translate(0 2158)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#3793df" />
          <stop offset={1} stopColor="#2473b5" />
        </linearGradient>
        <path
          fill="url(#prefix__b)"
          d="M0 260.3S237.2 396.5 538.5 317c168.3-44.4 293.5-77.8 438-85 69.7-3.5 265.4-2 265.4-30.9V40c0-22.1-17.9-40-40-40H40C17.9 0 0 17.9 0 40v220.3z"
        />
      </svg>
    </div>
  )
}

export default TitleSeperator
