import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss"
import ImageGallery from "react-image-gallery"
import "./gallery.scss"
import TitleSeperator from "../title-seperator-rounded/TitleSeperatorRounded.jsx"

const Gallery = () => {
  const galleryImages = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "galleryImages" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1024) {
                originalName
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  // console.log(galleryImages)
  let galleryArray = []
  galleryImages.allFile.edges.forEach(edge => {
    galleryArray.push({
      original: edge.node.childImageSharp.fluid.srcWebp,
      thumbnail: edge.node.childImageSharp.fluid.srcWebp,
      originalAlt: String(edge.node.childImageSharp.fluid.originalName)
        .replace(/-/g, " ")
        .replace(".png", ""),
      imageSet: [
        {
          srcSet: edge.node.childImageSharp.fluid.srcSetWebp,
          media: edge.node.childImageSharp.fluid.srcSetWebp,
        },
      ],
    })
  })

  // console.log(galleryArray)
  return (
    <div className="gallery section">
      <TitleSeperator title="Browse" subtitle="our latest plastering" />
      <div className="section-content">
        <ImageGallery className="gallery__slider" items={galleryArray} />
      </div>
    </div>
  )
}

export default Gallery
