import React from "react"
// import valPicture from "../../images/val-plastering-ceiling-for-spreads-plastering-services-gradient-blur.jpg"
import img1 from "../../images/plastering-jumbotron.webp"
import "./jumbotron.scss"
import Ratings from "../ratings/ratings"

const Jumbotron = () => (
  <div className="jumbotron-container">
    <div className="jumbotron">
      <h1 className="jumbotron__title-spreads">SPREADS</h1>
      <h2 className="jumbotron__title-plastering-services">
        PLASTERING SERVICES
      </h2>
      <h2 className="jumbotron__subtitle">
        40 YEARS OF PLASTERING EXCELLENCE FOR LONDON AND THE SOUTH
      </h2>
      <div className="jumbotron__ratings-container">
        <Ratings />
      </div>
      <img src={img1} alt="plastering" className="jumbotron__image" />
    </div>
  </div>
)

export default Jumbotron
