import React from "react"
import TitleSeperatorRounded from "../title-seperator-rounded/TitleSeperatorRounded.jsx"
import { IoMdCall } from "react-icons/io"
import { IoIosMail } from "react-icons/io"
import { IoMdText } from "react-icons/io"
import { IoIosPin } from "react-icons/io"
import "./contact.scss"

function callLogger(e) {
  typeof window !== "undefined" &&
    window.gtag("event", "click", {
      event_category: "call",
      event_action: "call",
      event_label: "call",
      event_value: 0,
    })
  //... Other logic here
  console.log("call tag fired")
}

function emailLogger(e) {
  typeof window !== "undefined" &&
    window.gtag("event", "click", {
      event_category: "email",
      event_action: "email",
      event_label: "email",
      event_value: 0,
    })
  //... Other logic here
  console.log("email tag fired")
}

function smsLogger(e) {
  typeof window !== "undefined" &&
    window.gtag("event", "click", {
      event_category: "sms",
      event_action: "sms",
      event_label: "sms",
      event_value: 0,
    })
  //... Other logic here
  console.log("sms tag fired")
}

const Contact = () => (
  <div className="contact section">
    <TitleSeperatorRounded title="Contact" subtitle="Val Powell" />
    <div className="contact__flexbox section-content">
      <div className="contact__text-flex">
        <p className="contact__text">
          We're available 24/7 for questions, quotes and call outs. Give us a
          call, text or email anytime to get a same day quote. We usually reply
          to messages within the hour.
        </p>
        <p>
          Most jobs will require a visit to determine the size of the walls for
          the work required. Callouts are free. No job is too big or too small.
        </p>
      </div>
      <div className="contact-info">
        <a
          className="contact-info__call-icon"
          href="tel:+447774201918"
          onClick={e => callLogger(e)}
        >
          <IoMdCall className="contact-info__icon contact-info__icon" />
        </a>
        <a
          className="contact-info__text-icon contact-info__icon"
          href="sms:+447774201918"
          onClick={e => smsLogger(e)}
        >
          <IoMdText className="contact-info__icon" />
        </a>
        <a
          className="contact-info__email-icon contact-info__icon"
          href="mailto:valetinepowell23@yahoo.com"
          onClick={e => emailLogger(e)}
        >
          <IoIosMail className="contact-info__icon" />
        </a>
        <a
          className="contact-info__location-icon contact-info__icon"
          href="https://g.page/spreads-plastering-services"
        >
          <IoIosPin className="contact-info__icon" />
        </a>
        <a
          className="contact-info__mobile-number-call"
          href="tel:+447774201918"
          onClick={e => callLogger(e)}
        >
          <span className="contact-info__title">Call</span>
          <br />
          <span className="contact-info__info">+447774201918</span>
        </a>
        <a
          className="contact-info__mobile-number-sms"
          href="tel:+447774201918"
          onClick={e => callLogger(e)}
        >
          <span className="contact-info__title">Text</span>
          <br />
          <span className="contact-info__info">+447774201918</span>
        </a>
        <a
          className="contact-info__email"
          href="mailto:valentinepowell23@yahoo.com"
          onClick={e => emailLogger(e)}
        >
          <span className="contact-info__title">Email</span>
          <br />
          <span className="contact-info__info">
            valentinepowell23@yahoo.com
          </span>
        </a>
        <a
          className="contact-info__location"
          href="https://g.page/spreads-plastering-services"
        >
          <span className="contact-info__title">Location</span>
          <br />
          <span className="contact-info__info">
            2 Christ Church Rd, Surbiton, KT5 8JJ
          </span>
        </a>
      </div>
    </div>
  </div>
)

export default Contact
