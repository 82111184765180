import React from "react"

function Trapezium(props) {
  return (
    <svg
      preserveAspectRatio="none"
      fill="none"
      viewBox="0 0 550 500"
      {...props}
    >
      <path fill="#fff" d="M0 501h551V2L50 0 0 501z" />
    </svg>
  )
}

export default Trapezium
