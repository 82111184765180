import React from "react"
// import { Link } from "gatsby"
import Trapezium from "../components/shapes/Trapezium.jsx"

import Layout from "../components/layout/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Jumbotron from "../components/jumbotron/jumbotron"
import Gallery from "../components/gallery/gallery"
import About from "../components/about/about"
import Contact from "../components/contact/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Expert Plasterers Since 1979" />
    <div className="jumbotron-about">
      <Jumbotron />
      <About />
      <Trapezium className="svg-trapezium" />
    </div>
    <div className="gallery-contact">
      <Gallery />
      <Contact />
    </div>
  </Layout>
)

export default IndexPage
