import React from "react"

function RatingStar(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <defs>
        <linearGradient id="myGradient">
          <stop offset="0%" stopColor="#FFDD00" />
          <stop offset="100%" stopColor="#FBB034" />
        </linearGradient>
      </defs>
      <path
        fill="url(#myGradient)"
        d="M256 372.686L380.83 448l-33.021-142.066L458 210.409l-145.267-12.475L256 64l-56.743 133.934L54 210.409l110.192 95.525L131.161 448z"
      />
    </svg>
  )
}

export default RatingStar
